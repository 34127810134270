.event-container .eventmain {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.event-container .eventmain .event {
  width: 211px;
}
.event-container .eventmain .image-container {
  width: 211px;
  height: 251px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  background-position: 100% 100%;
}

.event-container .eventmain .title {
  color: #fcfcfc;
  font-family: 'helvetica-font';
  font-size: 24px;
  font-style: normal;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  text-transform: uppercase;
}
.event-container .eventmain .edit-text {
  color: #eb2426;
  font-family: "SFProText-Regular";
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  font-weight: 900;
  line-height: normal;
}
.event-container .eventmain .bookmarkcontainer {
  width: 25px;
  height: 25px;
  background: #eb2426;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.event-container .eventmain .editbutton {
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media screen and (min-width: 767.98px) {
  .event-container .eventmain .event {
    width: 200px;
  }
  .event-container .eventmain .image-container {
    width: 200px;
    height: 235px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .event-container .eventmain .event {
    width: 140px;
  }
  .event-container .eventmain .image-container {
    width: 140px;
    height: 165px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .event-container .eventmain .event {
    width: 180px;
  }
  .event-container .eventmain .image-container {
    width: 180px;
    height: 211px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1499.98px) {
  .event-container .eventmain .event {
    width: 211px;
  }
  .event-container .eventmain .image-container {
    width: 211px;
    height: 251px;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1919.98px) {
  .event-container .eventmain .event {
    width: 270px;
  }
  .event-container .eventmain .image-container {
    width: 270px;
    height: 330px;
  }
}
@media screen and (min-width: 1920px) {
  .event-container .eventmain .image-container {
    width: 340px;
    height: 400px;
  }
  .event-container .eventmain .event {
    width: 340px;
  }
  .event-container .eventmain .bookmarkcontainer {
    width: 40px;
    height: 40px;
  }
  .event-container .eventmain .editbutton {
    width: 40px;
    height: 40px;
  }
}
