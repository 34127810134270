body {
  background: #000;
}
.signupPopup {
  margin: 15px 0px;
  height: 35px;
  width: 250px;
  padding: 0px 10px;
  background-color: black;
  color: #fcfcfc;
  font-size: 14px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 900;
}
.signupPopup::placeholder {
  font-size: 14px;
  font-weight: 900;
  color: #fcfcfc;
}

.location {
  margin: 15px 0px;
  height: 50px;
  width: 52%;
  padding: 0px 10px;
  background-color: black;
  color: #fcfcfc;
  font-size: 18px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  /* font-family: "WhyteInktrap-Black"; */
}
.location::placeholder {
  font-size: 18px;
  font-weight: 900;
  color: #fcfcfc;
}

.offers {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 114px;
  align-self: stretch;
  color: #fff;
  font-family: SFProText-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: black;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.offers::placeholder {
  font-size: 18px;
  font-weight: 900;
  color: #fcfcfc;
}

.cuisine {
  margin: 15px 0px;
  height: 50px;
  width: 52%;
  background-color: black;
  color: #fcfcfc;
  font-size: 18px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-image: url("../SVG/dropdown-arrow.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 0px 10px;
  cursor: pointer;
}

.select-dropdown-icon::after {
  content: "\f00c"; /* Font Awesome icon code for down arrow */
  font-family: FontAwesome;
  font-size: 16px;
  color: #000;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Remove the default border and padding */
.select-dropdown {
  border: none;
  padding: 0;
}

.events {
  margin: 15px 0px;
  height: 50px;
  width: 95%;
  padding: 0px 10px;
  background-color: transparent;
  color: #fcfcfc;
  font-size: 16px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 400;
  font-family: SFProText-Regular;
}
.events::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: "#484848";
  font-family: SFProText-Regular;
}

.text_area {
  margin: 15px 0px;
  height: 50px;
  width: 95%;
  padding: 10px;
  background-color: transparent;
  color: #fcfcfc;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 400;
  font-family: SFProText-Regular;
}
.text_area::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fcfcfc;
  font-family: SFProText-Regular;
}

*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
input[type="date"] {
  background-color: #1515154b;
  /* padding: 2%; */
  /* transform: translate(-50%, -50%); */
  font-family: SFProText-Regular;
  color: #ffffff;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color-scheme: dark;
}
::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  margin-right: -15px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../../assets/SVG/icons_calendar.svg") no-repeat;
  width: 50px;
  height: 10px;
  margin-left: 30px;
  border-width: thin;
}
.datepicker-toggle-button {
  position: absolute;
  right: 10;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../../assets/SVG/icons_calendar.svg'); */
  background-repeat: no-repeat;
}

/* input type time */

/* input[type="time"] {
  width: 95%;
  background-color: #15151500;
  padding: 2%;
  font-family: SFProText-Regular;
  color: #ffffff;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color-scheme: dark;
} */

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
/* input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
} */

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
/* input[type="time"]::-webkit-datetime-edit-text {
  padding: 5px;
} */

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
/* input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #151515;
  color: #fcfcfc;
  border-radius: 15%;
  padding: 5px;
} */

/* Minute */
/* input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #151515;
  color: #fcfcfc;
  border-radius: 15%;
  padding: 5px;
} */

/* AM/PM */
/* input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #151515;
  border-radius: 15%;
  color: #fff;
  padding: 5px;
} */

/* 'X' button for resetting/clearing time */
/* input[type="time"]::-webkit-clear-button {
  display: none;
} */

/* Up/Down arrows for incrementing/decrementing the value */
/* input[type="time"]::-webkit-inner-spin-button {
  display: none;
} */

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
} */

/* input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../../assets/SVG/dropdown_arrow.svg") no-repeat;
  width: 50px;
  height: 10px;
  margin-left: 30px;
  border-width: thin;
} */

/* Search input */

.reviews {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #fff;
  margin: 15px 0px;
  height: 35px;
  width: 75%;
  padding: 0px 10px;
  background-color: black;
  color: #fcfcfc;
  font-size: 14px;
  font-weight: 900;
  font-family: Montserrat;
}

.reviews::placeholder {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scrollDiv::-webkit-scrollbar {
  width: 8px;
  height: 20px;
}

.scrollDiv::-webkit-scrollbar-thumb {
  background-color: #eb2426;
  border-radius: 4px;
}

.scrollDiv::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scroller */
::-webkit-scrollbar {
  width: 0px;
}
/* 
::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

::-webkit-scrollbar-thumb:active {
  background-color: #444;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
} */
.react-multi-carousel-list {
  display: block !important;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin: auto;
  text-align: center !important;
}
.crowserl-item {
  height: 450px;
  width: 300px;
  margin: 20px;
  background-size: 100%;
  cursor: pointer;
  position: relative;
}

.react-multiple-carousel__arrow {
  position: absolute;
  transition: all 0.5s;
  border-radius: 50%;
  z-index: 9;
  border: 0;
  background: rgb(205 76 29) !important;
  min-width: 50px;
  min-height: 50px;
  opacity: 1;
  cursor: pointer;
  top: 50%;
  transform: translate(-50%, 0%);
}

/* Custom Time Picker */
.time-picker {
  position: relative;
}

.time-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #151515;
  border: 1px solid #ffffffa2;
  border-top: none;
  color: #fff;
  height: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.time-option {
  padding: 10px;
  cursor: pointer;
}

.time-option:hover {
  background-color: #252525;
}
.fade.modal.show {
  z-index: 1200;
  backdrop-filter: brightness(0.3);
}
.crowserl-item {
  height: 450px;
  width: 300px;
  margin: 20px;
  background-size: 100% 100%;
  cursor: pointer;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 2500px) {
  .crowserl-item {
    height: 600px;
    width: 400px;
  }
}
@media screen and (min-width: 950px) and (max-width: 1100px) {
  .crowserl-item {
    height: 390px;
    width: 260px;
  }
}
/* Event Card */
.disabled {
  pointer-events: none;
}

@media screen and (min-width: 1920px) {
  .customcontainerglobal {
    max-width: 1920px;
    margin: auto;
    position: relative;
    overflow: hidden;
  }
  .eventImagecontainer {
    max-width: 1920px;
    margin: auto;
    position: relative;
    overflow: hidden;
  }
}

.image-container.margin-top {
  margin-top: 20%;
}
.eventImagecard.margintop {
  margin-top: 30% !important;
}
.savingLoader {
  height: 15px !important;
  width: 15px !important;
  margin-left: 10px;
}

/* login and Signup Input Design */
.form-parent {
  width: 350px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}
.form-parent .lable-name {
  text-align: justify;
  font-size: 16px;
  font-family: WhyteInktrap-Black;
}
.form-parent .input-field {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  width: 100% !important;
}
.customhalfbg {
  background: #181818;
  padding: 10px;
}
.media-slider-top-data {
  padding: 10px;
}
.media-slider-number {
  color: #fcfcfc;
  text-align: center;
  font-family: "WhyteInktrap-Black";
  font-size: 20px;
  font-style: italic;
  font-weight:  400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  text-transform: uppercase;
}
.media-slider-text {
  color: #fcfcfc;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
}
.media-slider-down-data {
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #fcfcfc;
  font-family: "WhyteInktrap-Black";
  font-size: 20px;
  font-style: italic;
  font-weight:  400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  text-transform: uppercase;
}
