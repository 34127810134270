/* .container {
  padding: 0px 90px 0px 135px;
  max-width: 100%;
} */

.text-f {
  color: #fcfcfc;
  font-family: "WhyteInktrap-Black";
  font-size: 32px;
  font-style: normal;
  font-weight:  400;
  line-height: normal;
  text-transform: none;
}

.link {
  text-decoration: none;
  cursor: pointer;
}

.text2-f {
  color: #fff;
  font-family: "WhyteInktrap-Black";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cursor-pointer {
  cursor: pointer;
}
