/* .first-time-viewers-card {
  width: 100%;
  height: 300px;
  background-image: url("../../../assets/images/Vector_viewers.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 24px 27px;
} */
.first-viewers-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  @media (max-width: 1280px) {
    width: 500px;
    height: 140px;
  }
  @media (max-width: 1024px) {
    width: 460px;
    height: 120px;
  }
}

.first-viewers-cards {
  background-size: 100% 100%;
  background-image: url("../../../assets/images/Vector_viewers.png");
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover; /* Ensure the image covers the container */
  /* @media (max-width: 1250px) {
    width: 660px;
    height: 185px;
  }
  @media (max-width: 1000px) {
    width: 660px;
    height: 185px;
  } */
}

.pad-ft {
  padding: 10px 24px;
}

.mar-left-ft {
  margin-left: 10px;
}

.mar-top-ft {
  margin-top: -10px;
}

.donut-chart {
  position: relative;
  width: 110px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }
}
.donut-chart svg {
  width: 100% !important;
  height: 100% !important;
}
.donut-text-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-circle-ftv {
  width: 40px;
  height: 40px;
  background-color: #4e0aa5;
  border-radius: 50%;
  @media (max-width: 1280px) {
    width: 25px;
    height: 25px;
  }
}

.text-ftv {
  color: #fff;
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-left: 10px;
  @media (max-width: 1280px) {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
  }
}

.total-no {
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  background: #181818;
  @media (max-width: 1280px) {
    padding: 8px 16px;
  }
}

.text2-ftv {
  color: #5d5d5d;
  font-family: "helvetica-font";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 12px;
    font-weight: 600;
  }
}

.text3-ftv {
  color: #fff;
  font-family: "helvetica-font";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 20px;
    font-weight: 600;
  }
}

.text4-ftv {
  color: #29ca1b;
  font-family: "helvetica-font";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 20px;
    font-weight: 600;
  }
}

.text5-ftv {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: SFProText-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 10px;
    font-weight: 500;
  }
}

.text6-ftv {
  color: #fff;
  font-family: SFProText-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-left: 5px !important;
  @media (max-width: 1280px) {
    font-size: 8px;
    font-weight: 600;
  }
}

.text-white {
  color: #fff;
}

.slider-box {
  position: relative;
}

/* .slider-circle {
  width: 22px;
  height: 22px;
  position: absolute;
  right: -11px;
  top: 5px;
  border-radius: 15px;
  background: #262626;
  box-shadow: 0px 0px 2px 0px rgba(14, 123, 224, 0.1);
} */

.slider {
  /* width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px; */
}

.price-container {
  padding-top: 25px;
  @media (max-width: 1280px) {
    padding-top: 20px;
  }
}

.price-card {
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  @media (max-width: 1280px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    padding: 2px;
    gap: 2px;
  }
}

.bg-price1 {
  background-color: #231534;
}

.bg-price2 {
  background-color: #172438;
}

.bg-price3 {
  background-color: #183842;
}

.bg-price4 {
  background-color: #183842;
}

.point-ftv {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  @media (max-width: 1280px) {
    width: 10px;
    height: 10px;
  }
}

.point1 {
  width: 5px;
  height: 5px;
  background-color: #4e0aa5;
  border-radius: 50%;
}

.point2 {
  width: 5px;
  height: 5px;
  background-color: #1255b9;
  border-radius: 50%;
}

.point3 {
  width: 5px;
  height: 5px;
  background-color: #19b7e9;
  border-radius: 50%;
}

.point4 {
  width: 5px;
  height: 5px;
  background-color: #1de8db;
  border-radius: 50%;
}

.accordin-container {
  margin-top: 16px;
  padding: 12px 40px;
  width: 100%;
  height: 73px;
  border-radius: 12px;
  background: #1e1726;
  cursor: pointer;
}

.alp {
  padding: 12px 40px 12px 10px;
}

.accordin-container-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-test {
  display: flex;
  flex-direction: row;
}

.align {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cell1 {
  width: 28%;
}

.cell-head2 {
  width: 10%;
}

.cell {
  width: 18%;
}

.dyn-height {
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
}

.dyn-height::-webkit-scrollbar {
  border-radius: 4px;
  width: 0.5em;
  background-color: #000000;
}

.dyn-height::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #eb2426;
}

.dyn-height::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #000000;
}

.align-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pad-left {
  padding-left: 50px;
}

.accordin-summary {
  background-color: #000000;
}

.text-grey {
  color: #9e9e9e;
}

input {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 6px 16px;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #000;
  color: #fff;
  /* position: relative; */
}

.input-field {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tr {
  border-radius: 28px;
  background: #000;
}

/* .MuiTable-root {
  background-color: red;
} */

/* .MuiTableContainer-root {
  margin-top: -20px;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid #000;
  background: yellow;
} */

/* .MuiTableRow-root {
  width: 100%;
  border-radius: 28px;
  background: red;
} */

.table-container {
  width: 100%;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid #000;
  background: #101010;
  padding: 51px;
}

.table-head {
  display: flex;
  width: 100%;
  padding-right: 0px;
  justify-content: space-between;
  align-items: flex-end;
}

.chart-container {
  width: 100%;
  display: inline-flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  background: #181818;
  margin-top: 20px;
}

.chart-card {
  background-color: #000;
  border-radius: 32px;
  padding: 22px;
}

.progress-bar {
  height: 22px;
  border-radius: 18px 0px 0px 18px;
  background: #4e0aa5;
  color: #29ca1b;
  text-align: right;
  font-family: "helvetica-font";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
}

.progress-bar-container {
  width: 100px;
  height: 22px;
  border-radius: 18px;
  background-color: #000000;
  position: relative;
}

.progress-bar-level {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #29ca1b;
  text-align: right;
  font-family: "helvetica-font";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.circular-progress-bar {
  width: 80%;
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 1280px) {
    width: 90%;
    margin-top: 10px;
  }
}

.progress-bar-child {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: center;
}

.progress-bar-text {
  color: #fff;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 13px;
    font-weight: 400;
  }
}

.CircularProgressbar {
  width: 165%;
  vertical-align: middle;
}

.CircularProgressbar {
  stroke: #ff640d;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

.circle-text1 {
  color: #fff;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-green {
  color: #29ca1b;
}

.input-field-table {
  color: #9e9e9e;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.donut-text1-ftv {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1280px) {
    font-size: 18px;
    font-weight: 350;
  }
}

.donut-text2-ftv {
  color: #fff;
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-green {
  color: #29ca1b;
}

.box-shadow-violet-ftv {
  box-shadow: 0px 0px 13px 2px rgba(78, 10, 165, 1);
  border-radius: 20px;
}
.ul-counttext {
  column-count: 2;
  column-gap: 10px;
  padding-left: 0px;
  margin: 0px;
  list-style: none;
  padding: 0px;
}
.ul-counttext li {
  display: flex;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
}
