.text-cb {
  color: #5d5d5d;
  font-family: "SFProText-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text2-cb {
  color: #fff;
  font-size: 14px;
}

.text4-cb {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "SFProText-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-white {
  color: #fff;
}

.slider-box {
  position: relative;
}

.slider-circle {
  width: 22px;
  height: 22px;
  position: absolute;
  right: -3px;
  top: 5px;
  border-radius: 15px;
  background: #262626;
  box-shadow: 0px 0px 2px 0px rgba(14, 123, 224, 0.1);
}
