.instapostcontainer {
  display: block;
}
.instapostcontainer .post-image {
  width: 100%;
  height: 240px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.shop-now {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  color: #000;
  .text {
    color: #1ea1f7;
    font-size: 13px;
    font-weight: 600;
  }
}
.shop-now .insta-icon {
  margin-right: 7px;
  width: 20px;
}
.shop-now .description {
  font-size: 10px;
  font-family: "Roboto";
  font-weight: 400;
  margin-bottom: 21px;
}

.post-image .editbtn {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.post-image .editbtn {
  opacity: 0;
}
.post-image:hover .editbtn {
  opacity: 1;
}
.eventtitle {
  font-family: "WhyteInktrap-Black";
  text-align: center;
  font-size: 30px;
}
.instapostcontainer .post-image .logoicon {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
