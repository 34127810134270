.dashboard-top {
  margin-top: 100px;
}
.text-orange {
  color: #eb2426;
}
/* Breadcrumb */
.breadcrumb .arrowdata {
  width: 10px;
}

.line {
  margin-bottom: 30px;
  width: 100%;
  height: 3px;
  background: #000;
  stroke-width: 4px;
  stroke: #000;
  flex-shrink: 0;
}
/* TabDesign */
.tab-container {
  display: flex;
  width: 100%;
}

.tab-container .tab-item {
  display: block;
  width: 20%;
}
.dashboard-card {
  margin: 20px 0px;
  background: #181818;
  border-radius: 30px;
  padding: 30px !important;
  height: auto !important;
}
.totalbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.totalbox-item {
  width: 20%;
  display: block;
  padding: 10px;
}
.card-align {
  height: 55px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.total-card {
  border-radius: 8px 0px 0px 8px;
  background: #82b343;
  background-color: #82b343;
  color: white;
}
.first-viewers-card {
  background: #4e0aa5;
  color: white;
}

.engaged-viewers-card {
  background: #1255b9;
}

.consumers-card {
  background: #19b7e9;
}

.diners-card {
  border-radius: 0px 8px 8px 0px;
  background: #1de8db;
}
.text-d {
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: none;
  margin: 0px 5px !important;
}
.card-icon {
  width: 25px;
}
.under-line {
  margin: 5px 0px;
  width: 100%;
  height: 3px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #eb2426;
}
.text-head.text-gray {
  color: #9e9e9e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  font-family: "SFProText-Regular";
  margin: 0px 0px !important;
}
.line {
  margin: 20px 0;
  width: 100%;
  height: 3px;
  background: #000;
  stroke-width: 4px;
  stroke: #000;
  flex-shrink: 0;
}
.total-text {
  font-size: 12px;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  margin: -2px;
  padding: 0px;
  text-align: center;
}
.text-over {
  position: absolute;
  text-align: center;
  top: 6px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.darrow {
  width: 11px;
  line-height: 1;
}
.tabheaderbox {
}
.downarrow {
  width: 30px;
}

/* Total Cards */
.CircularProgressbar {
  stroke: #262626;
}
/* .first-time-viewers-card {
  width: 100%;
  height: 300px;
  background-image: url("../../../assets/images/Vector_viewers.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 24px 27px;
} */

.card {
  width: 100%;
  height: 410px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  padding: 14px 16px;
  flex-direction: column;
  /* gap: 32px; */
  border-radius: 20px;
  /* background: #000; */
}
/* first container */
.total-container {
  width: 100%;
  min-height: 350px;
  overflow: hidden;
  background: #000;
  background-size: 100% 100%;
}

.total-cards {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 20px;
  object-fit: cover;
}

/* Responsive Image Container */
.responsive-image-container {
  position: relative;
  width: 100%; /* Make the container width responsive */
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
  overflow: hidden;
}

/* Image Wrapper */
.image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Responsive Image */
.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust the value as needed (cover, contain, etc.) */
  object-position: center center; /* Adjust the position as needed */
}

.circular-progress-bar-total {
  margin: 1px 0;
  width: 50%;
  position: relative;
}

.view-circle {
  width: 40px;
  height: 40px;
  background-color: #4e0aa5;
  border-radius: 50%;
}

.text-head {
  color: #9e9e9e;
  font-family: "SFProText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text {
  color: #fff;
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin: 0px 10px !important;
  text-transform: none;
}

.text-light-gray {
  color: #5d5d5d;
}

/* .total-no {
    display: flex;
    width: 200px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: #181818;
  } */

.text2 {
  color: #5d5d5d;
  font-family: "SFProText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text3 {
  color: #fff;
  font-family: "SFProText-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text4 {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "SFProText-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text5 {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "SFProText-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text6 {
  color: #fff;
  font-family: "SFProText-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-white {
  color: #fff;
}

.slider-box {
  position: relative;
}

/* .slider-circle {
    width: 22px;
    height: 22px;
    position: absolute;
    right: -11px;
    top: 5px;
    border-radius: 15px;
    background: #262626;
    box-shadow: 0px 0px 2px 0px rgba(14, 123, 224, 0.1);
  } */

.slider {
  /* width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px; */
}
/* 
  .price-container {
    padding-top: 100px;
  } */

.pc {
  padding: 16px 12px;
}

.price-card {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
}

.bg-price1 {
  background-color: #231534;
}

.bg-price2 {
  background-color: #172438;
}

.bg-price3 {
  background-color: #183842;
}

.bg-price4 {
  background-color: #183842;
}

.point1 {
  width: 5px;
  height: 5px;
  background-color: #4e0aa5;
  border-radius: 50%;
}

.point2 {
  width: 5px;
  height: 5px;
  background-color: #1255b9;
  border-radius: 50%;
}

.point3 {
  width: 5px;
  height: 5px;
  background-color: #19b7e9;
  border-radius: 50%;
}

.point4 {
  width: 5px;
  height: 5px;
  background-color: #1de8db;
  border-radius: 50%;
}

/* .accordin-container {
    margin-top: 40px;
    padding: 24px 40px;
    width: 100%;
    border-radius: 12px;
    background: #1e1726;
    cursor: pointer;
  } */

.alp {
  padding: 24px 40px 24px 10px;
}

/* .accordin-container-align {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  } */

.align-test {
  display: flex;
  flex-direction: row;
}

.align {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cell1 {
  width: 28%;
}

.cell-head2 {
  width: 10%;
}

.cell {
  width: 18%;
}

.dyn-height {
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
}

.dyn-height::-webkit-scrollbar {
  border-radius: 4px;
  width: 0.5em;
  background-color: #000000;
}

.dyn-height::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #eb2426;
}

.dyn-height::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #000000;
}

.align-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pad-left {
  padding-left: 50px;
}

.accordin-summary {
  background-color: #000000;
}

.text-grey {
  color: #9e9e9e;
}

.tr {
  border-radius: 28px;
  background: #000;
}

.table-container {
  width: 100%;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid #000;
  background: #101010;
  padding: 51px;
}

.table-head {
  display: flex;
  width: 100%;
  padding-right: 0px;
  justify-content: space-between;
  align-items: flex-end;
}

.chart-container {
  width: 100%;
  display: inline-flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  background: #181818;
  margin-top: 20px;
}

.chart-card {
  background-color: #000;
  border-radius: 32px;
  padding: 22px;
}

.CircularProgressbar {
  stroke: #ff640d;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.circle-text1 {
  color: #fff;
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-green {
  color: #29ca1b;
}

.text-size {
  font-size: 16px;
}

.text-size1 {
  font-size: 12px;
}

.bg-black {
  background-color: #000;
}
.select-total {
  /* width: 50%; */
  border-radius: 20px;
}

.point {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  @media (max-width: 1280px) {
    width: 10px;
    height: 10px;
  }
}

.bg-violet {
  background-color: #4e0aa5;
}

.bg-blue {
  background-color: #1255b9;
}

.bg-navyblue {
  background-color: #19b7e9;
}

.bg-lightgrey {
  background-color: #1de8db;
}

.bg-orange {
  background-color: #eb2426;
}

.bg-green {
  background-color: #29ca1b;
}

.box-shadow-violet {
  box-shadow: 0px 0px 13px 2px rgba(78, 10, 165, 1);
  background-image: url("../../assets/images/Vector_card.png");
}

.box-shadow-blue {
  box-shadow: 0px 0px 12px 0px rgba(18, 85, 185, 0.7);
  background-image: url("../../assets/images/Vector_card2.png");
}

.box-shadow-navyblue {
  box-shadow: 0px 0px 12px 0px rgba(25, 183, 233, 0.7);
  background-image: url("../../assets/images/Vector_card3.png");
}

.box-shadow-lightgrey {
  box-shadow: 0px 0px 12px 0px rgba(29, 232, 219, 0.7);
  background-image: url("../../assets/images/Vector_card4.png");
}

.circle-container {
  position: relative;
  width: 135px;
  height: 135px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.text7 {
  color: #fff;
  font-family: "WhyteInktrap-Black";
  font-size: 24px;
  font-style: italic;
  font-weight:  400;
  line-height: normal;
  text-align: center;
  margin: 0px !important;
}

.card-container-total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.total-text-green {
  color: #29ca1b;
  font-family: "WhyteInktrap-Black";
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5; /* 24px */
  text-align: center;
  margin: 0px !important;
}

.bg-black-total {
  border-radius: 20px;
  background: #000;
}

.text5-total {
  color: #fff;
  font-family: "SFProText-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
}

.progress-bar-text-total {
  color: #fff;
  font-family: "SF Pro Display";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  margin: 0px !important;
  padding: 0px !important;
}
.tabheaderbox {
  margin: 0px 0px 0px 8px;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-family: "SFProText-Regular";
}
.progress-bar-child-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-green {
  color: #29ca1b;
}

.text-total {
  color: #5d5d5d;
  font-family: SFProText-Regular;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  margin: 0px !important;
}

.ml-40-total {
  margin-left: 40px;
}

/* Total  */
.total-circle {
  width: 100%;
  height: 120px;
  position: relative;
}
.total-circle .voilet {
  width: 120px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.total-circle .blue {
  width: 90px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.total-circle .sky {
  width: 60px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
.header-text {
  margin: 0px !important;
  display: inline-flex;
  align-items: center;
  gap: 2px;
}
.total-circle .green {
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
