.customDatePicker {
    /* opacity: 0; */
    display: none;
}

.active {
    opacity: 1;
    display: block;
}

.MuiDateCalendar-root {
    display: block;
}

.customDatePicker .MuiFormControl-root {
    border: 1px solid #767676;
    margin-left: 15px;
    border-radius: 30px;
    background-color: #0B0B0B;
    margin-bottom: 10px;
    opacity: 0;
    height: 0px;
}
.DateRangePicker__WeekdayHeading{
    border: none !important;
}
.DateRangePicker__WeekdayHeading abbr[title] {
    color: #767676 !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
}
.DateRangePicker__Date--weekend{
    background-color: transparent !important;
    border:none !important
}
.DateRangePicker__Date{
    border: transparent !important;
}
.DateRangePicker__DateLabel{
    color: #fff !important;
}

.customDatePicker .MuiFormLabel-root {
    color: #fff;
    display: none;
}

.customDatePicker .MuiButtonBase-root {
    color: #eb2426;
}

.customDatePicker .MuiInputBase-input {
    width: 100px;
    color: #fff;
    height: 0px;
    padding: 0px;
}

.MuiPickersDay-root {
    color: #fff !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: #eb2426 !important;
}

.MuiPickersLayout-contentWrapper {
    background-color: #000;
}

.MuiDateCalendar-root {
    background-color: #0B0B0B;
    
}

.MuiDayCalendar-weekDayLabel {
    color: #a3a3a3 !important;
}

.MuiPickersFadeTransitionGroup-root div {
    color: #fff !important;
}

.custom-radio input[type="radio"] {
    /* Hide the default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Create a custom radio button */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #333;
    /* Border color */
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

/* Style for checked state */
.custom-radio input[type="radio"]:checked {
    background-color: #333;
    /* Checked color */
}

.TimePicker {
    /* position: absolute; */
    top: 420px;
    padding-left: 20px;
    margin-left: 20px;
    padding-top: 10px;
    background-color: #0B0B0B;
    width: 350px;
    z-index: 9999;
    padding-bottom: 20px;
    border: 1px solid #767676;
    border-top: none;
    border-top: 1px solid #767676;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.MuiPopover-root .MuiPickersCalendar-root:focus-visible {
    outline: none !important;
}
.DateRangePicker{
    margin-top: 20px !important;
}
.rdrCalendarWrapper{
    border: 1px solid #767676 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    width: 350px !important;
    margin-left: 20px;
    margin-top: 5px;
    height: 280px;
    background-color: #000 !important;
}
.rdrMonthAndYearWrapper{
    background-color: #000 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.DateRangePicker__MonthHeader{
    color: #fff !important;
}
.DateRangePicker__Date--is-highlighted .DateRangePicker__DateLabel{
    color: #eb2426 !important;
}
.react-date-range-preview {
    display: none !important; /* Hides the selection preview sidebar */
  }
  
  .rdrDefinedRangesWrapper {
    display: none !important; /* Hides the calendar sidebar */
  }
  .rdrDateDisplayWrapper{
    display: none !important; /* Hides the calendar sidebar */
  }
  .rdrMonths{
    background-color: #000 !important;
  }
  .rdrDayNumber span {
    color: #fff !important;
}
.rdrStartEdge ,.rdrInRange,.rdrEndEdge{
    color: #eb2426 !important;
}
.rdrMonthAndYearPickers select{
    color: #fff !important;
}
.dateTimepicker-body .modal-content{
    background-color: transparent;
}
.rdrWeekDay{
    text-transform: uppercase !important;
    font-weight: 500 !important;
}
.rdrMonthPicker,.rdrYearPicker{
    padding:0px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}