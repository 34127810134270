/* .container {
  margin-top: 100px;
  padding: 100px 90px 50px 135px;
  max-width: 100%;
} */

.card-container {
  /* width: 1652px;
  height: 752px; */
  flex-shrink: 0;
  border-radius: 32px;
  background: #181818;
  padding: 50px 10px;
}

.header-text {
  margin: 25px 0;
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.total-card {
  /* width: 324px; */
  /* height: 71px; */
  border-radius: 8px 0px 0px 8px;
  background: #82b343;
  background-color: #82b343;
  color: white;
}

.first-viewers-card {
  /* width: 324px;
  height: 71px; */
  background: #4e0aa5;
  color: white;
}

.engaged-viewers-card {
  /* width: 324px;
  height: 71px; */
  background: #1255b9;
}

.consumers-card {
  /* width: 405px;
  height: 71px; */
  background: #19b7e9;
}

.diners-card {
  /* width: 324px;
  height: 71px; */
  border-radius: 0px 8px 8px 0px;
  background: #1de8db;
}

.text-d {
  text-align: center;
  font-family: "SFProText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-left: 10px;
  text-transform: none;
}

.text-weight {
  font-weight: 400;
}

.text-orange {
  color: #eb2426;
}

.text-gray {
  color: #9e9e9e;
}

.card-align {
  height: 71px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.line {
  margin: 30px 0;
  width: 100%;
  height: 3px;
  background: #000;
  stroke-width: 4px;
  stroke: #000;
  flex-shrink: 0;
}

.cards {
  width: 100%;
  height: 20rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  /* width: 284px; */
  padding: 14px 16px;
  flex-direction: column;
  /* gap: 32px; */
  border-radius: 20px;
  /* background: #000; */
}

.cards-align {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-light-gray {
  color: #5d5d5d;
}

.text2 {
  color: #fff;
  font-family: "WhyteInktrap-Black";
  font-size: 24px;
  font-style: italic;
  font-weight:  400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5; /* 28.8px */
  text-align: center;
}

.first-circle {
  width: 125px;
  height: 125px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #4e0aa5;
}

.second-circle {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #1255b9;
}

.third-circle {
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #19b7e9;
}
.fourth-circle {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #1de8db;
}

.circle-container {
  position: relative;
  width: 135px;
  height: 135px;
  margin-left: 10px;
  flex-shrink: 0;
}

.circle-align {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text3 {
  color: #fcfcfc;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: WhyteInktrap-Black;
  font-size: 14px;
  font-style: italic;
  font-weight:  400;
  line-height: 1.5;
  -webkit-line-clamp: 1.5;
  text-transform: uppercase;
  z-index: 1;
}

.text4 {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: SFProText-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-white {
  color: #fff;
}

.text5 {
  color: #fff;
  font-family: SFProText-Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.view-circle {
  width: 40px;
  height: 40px;
  background-color: #4e0aa5;
  border-radius: 50%;
}

.align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  background-color: bisque;
}

.bg-black {
  background-color: #000;
}

.chart-container {
  width: 100%;
  display: inline-flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  background: #181818;
  margin-top: 20px;
}

.chart-card {
  background-color: #000;
  border-radius: 32px;
  padding: 22px;
}

.high-charts {
  background-color: #390e0e;
  color: #fff;
}

.highcharts-alternate-grid {
  background-color: #000;
}

.highcharts-area {
  fill: #000;
}

.text6 {
  color: #afafaf;
  font-family: SFProText-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tabs {
  background-color: #000;
  color: #fff;
}

.under-line {
  margin: 12px 0px;
  width: 100%;
  height: 4px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #eb2426;
}
