.socialmediatabs{
    width: max-content !important;
    border:1px solid #fff !important;
    border-radius: 27px;
}
.socialmediatabs .MuiButtonBase-root{
    border-radius: 50%;
    min-width:50px !important;
    width:40px !important;
    min-height: 40px !important;
    height: 50px !important;
    text-transform: none;
    font-size: 18px;
    padding: 10px;
}
.socialmediatabs .Mui-selected{
    background-color: #fff;
    border-bottom: 0px;
    color: #eb2426 !important;

}